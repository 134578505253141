import { EducationItem, EducationItems } from '../../items/educationItem';
import './Education.scss';
import pin from '../../images/pin.svg';

function educationItem(item:EducationItem, index:number) {
  let key = "educationItem_" + index;
  return (
    <div className='education-item' key={key}>

      <div className='education-details'>
        <div className='education-name title'>{item.name}</div>
        <div>{item.date}</div>
        <div>{item.degree}</div>
        <div className='education-location'>
          <div><img src={pin} /></div>
          <div>{item.location}</div>
        </div>
      </div>

    </div>
  );
}

export default function Education() {
  return (
    <div className='education-container'>

      <div className='section-header'>
        Education
        <hr/>
      </div>

      <div className='education-content'>
        {EducationItems.getItems().map((item, index) => {
          return educationItem(item, index);
        })}
      </div>

    </div>
  )
}
