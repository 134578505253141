import { HeaderLinkType } from '../../constants';
import './Header.scss';

interface Props {
  headerLinkSelected: Function
}

export default function Header(props: Props) {

  function onSelected(linkType: HeaderLinkType) {
    props.headerLinkSelected(linkType);
  }

  return (
    <div className='header-container header-fixed'>
      <div className='header-content'>
        <div className='header-item' onClick={() => {onSelected(HeaderLinkType.EDUCATION)}}>
          Education
        </div>
        
        <div className='header-item' onClick={() => {onSelected(HeaderLinkType.WORK_HISTORY)}}>
          Work History
        </div>
        
        <div className='header-item' onClick={() => {onSelected(HeaderLinkType.PROJECTS)}}>
          Projects
        </div>
      </div>
    </div>
  )
}
