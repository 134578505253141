import { WorkItem, WorkItems } from '../../items/workItems';
import './WorkHistory.scss';
import pin from '../../images/pin.svg';
import convertHtmlToReact from '@hedgedoc/html-to-react';

function getWorkItems(item:WorkItem, index: number) {
  let key = "workItem_" + index;
  return (
    <div className='work-item' key={key}>

      <div className='company-details'>
        <div className='company-logo'><img src={item.logo}/></div>
        <div>{item.company}</div>
        <div>{item.date}</div>
        <div className='work-location'>
          <div><img src={pin} /></div>
          <div>{item.location}</div>
        </div>
      </div>

      <div className='work-details'>
        <div className='title'>{item.team}</div>
        <div className='work-title'>{item.title}</div>
        <div className='work-description'>{convertHtmlToReact(item.description)}</div>
        <div className='skills'>
          {item.skills.map((skill, sIndex) => {
            return <span className='skill' key={key + "_skill" + sIndex}>{skill}</span>
          })}
        </div>
      </div>

    </div>
  );
}


export default function WorkHistory() {
  return (
    <div className='wh-container'>

      <div className='section-header'>
        Work History
        <hr/>
      </div>

      <div className='wh-content'>
        {WorkItems.getItems().map((item, index) => {
          return getWorkItems(item, index);
        })}
      </div>

    </div>
  )
}
