class Analytics {


    public static initializeAnalytics() {
    }

    public static moreButtonClicked(project:string) {
        this.reportProjectEvent(project + "_more");
    }

    public static socialButtonClicked(social:string) {
      this.reportClickEvent(social + "_social_click");
  }

    private static reportClickEvent(event:string) {
    }

    private static reportProjectEvent(event:string) {
    }

}

export { Analytics }