import './Projects.scss';
import React, { useEffect, useState } from 'react';
import { ProjectItem, ProjectItems } from '../../items/projectItem';
import next_button from '../../images/next.svg';
import prev_button from '../../images/prev.svg';
import more_button from '../../images/more.svg';
import less_button from '../../images/less.svg';
import convertHtmlToReact from '@hedgedoc/html-to-react';
import { Analytics } from '../../analytics';

function ProjectItemContainer(item: ProjectItem, index: number) {

  const [showMoreSection, setShowMoreSection] = useState(false);
  const [moreImage, setMoreImage] = useState(more_button);
  const [moreText, setMoreText] = useState("More");
  const [moreImageIndex, setMoreImageIndex] = useState(0);

  const moreSectionRef = React.useRef<HTMLInputElement>(null);
  let key = "projectItem_" + index;

  useEffect(() => {
    if (showMoreSection) {
      setMoreImageIndex(0);
      setMoreImage(less_button);
      setMoreText("Less");
      moreSectionRef.current!.style.height = item.moreHeight + 'px';
    } else {
      setMoreImage(more_button);
      setMoreText("More")
      moreSectionRef.current!.style.height = '0px';
    }
  }, [showMoreSection]);

  function toggleMoreSection() {
    if (!showMoreSection) {
      Analytics.moreButtonClicked(item.name);
    }
    setShowMoreSection(!showMoreSection);
  }

  function nextImage() {
    if (moreImageIndex < item.images.length-1) {
      setMoreImageIndex(moreImageIndex+1);
    }
  }

  function previousImage() {
    if (moreImageIndex > 0) {
      setMoreImageIndex(moreImageIndex-1);
    }
  }

  function getArrowClass(direction:string) {
    let className = 'more-nav-btn';
    if (direction === 'prev' && moreImageIndex === 0) className += ' inactive';
    if (direction === 'next' && moreImageIndex === item.images.length-1) className += ' inactive';
    return className;
  }

  return (
    <div className='project-item' key={key}>

      <div className='project-item-content'>

        <div className='project-info'>
          <img src={item.icon} />
        </div>

        <div className='project-details'>

          <div className='project-header'>
            <div className='title'>{item.name}</div>
            <div className='project-platforms'>
              {item.platforms.map((platform, platformIndex) => {
                return <div key={key + "_platform_" + platformIndex}>
                  <img src={platform} />
                </div>
              })}
            </div>
          </div>

          <div className='project-release-date'>{item.date}</div>
          <div className='project-description'>{convertHtmlToReact(item.description)}</div>
        </div>

      </div>

      <div className="more-button" onClick={() => toggleMoreSection()}>
        <div>{moreText}</div>
        <div><img src={moreImage}/></div>
      </div>

      <div className='more-container more-content' ref={moreSectionRef}>

        <div className='more-image-viewer'>
          <div className={getArrowClass("prev")} onClick={() => {previousImage()}}>
            <img src={prev_button} />
          </div>
          
          <div className='more-images'>
            <img src={item.images[moreImageIndex]}/>
          </div>
          
          <div className={getArrowClass("next")} onClick={() => {nextImage()}}>
            <img src={next_button} />
          </div>
        </div>

        <div className='more-description'>
          <div className='text'>{convertHtmlToReact(item.details)}</div>
        </div>
      </div>

    </div>
  );
}

export default function Projects() {
  return (
    <div className='projects-container'>

      <div className='section-header'>
        Projects
        <hr/>
      </div>

      <div className='projects-content'>
        {ProjectItems.getItems().map((item, index) => {
          return ProjectItemContainer(item, index);
        })}
      </div>

    </div>
  )
}
