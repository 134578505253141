import 'globalthis/polyfill';
import 'globalthis/auto';
import './App.scss';
import React, { useRef } from 'react'
import AboutMe from './components/AboutMe/AboutMe';
import Education from './components/Education/Education';
import Header from './components/Header/Header';
import Projects from './components/Projects/Projects';
import WorkHistory from './components/WorkHistory/WorkHistory';
import { HeaderLinkType } from './constants';
import { Analytics } from './analytics';

Analytics.initializeAnalytics();

function App() {

  const aboutMeRef = React.useRef<HTMLInputElement>(null);
  const educationRef = React.useRef<HTMLInputElement>(null);
  const workHistoryRef = React.useRef<HTMLInputElement>(null);
  const projectsRef = React.useRef<HTMLInputElement>(null);

  function headerLinkSelected(linkType: HeaderLinkType) {
    let selectedRef;
    switch (linkType) {
      case HeaderLinkType.ABOUT_ME:
        selectedRef = aboutMeRef;
        break;
      case HeaderLinkType.EDUCATION:
        selectedRef = educationRef;
        break;
      case HeaderLinkType.WORK_HISTORY:
        selectedRef = workHistoryRef;
        break;
      case HeaderLinkType.PROJECTS:
        selectedRef = projectsRef;
        break;
    }

    if (selectedRef) {
      selectedRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  return (
    <div className="App">
      <Header headerLinkSelected={headerLinkSelected} />

      <div className="section-parent-anchor"><div ref={aboutMeRef} className="section-anchor"></div></div>
      <AboutMe />
      
      <div className="section-parent-anchor"><div ref={educationRef} className="section-anchor"></div></div>
      <Education />

      <div className="section-parent-anchor"><div ref={workHistoryRef} className="section-anchor"></div></div>
      <WorkHistory />
      
      <div className="section-parent-anchor"><div ref={projectsRef} className="section-anchor"></div></div>
      <Projects />
    </div>
  );
}

export default App;
