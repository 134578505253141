import google from '../images/logos/google.png';
import rnp from '../images/logos/rnp.png';
import ibm from '../images/logos/ibm.png';
import microsoft from '../images/logos/microsoft.png';
import intel from '../images/logos/intel.png';

interface WorkItem {
  logo: string;
  company: string;
  date: string;
  location: string;
  team: string;
  description: string;
  title: string;
  skills: string[];
}

class WorkItems {

  private static items: WorkItem[] = [
    {
      logo: google,
      company: "Google",
      date: "Sep 2019 - Present",
      location: "Austin, TX",
      team: "Google Play Services",
      title: "Senior Software developer",
      description: "Developer for <a href='https://developers.google.com/android/guides/overview' target='_blank'>Google's mobile services platform</a>, which provides regularly updated Google services and APIs to 3+ billion Android devices.",
      skills: ["Java", "Android", "Kotlin", "Python"]
    },
    {
      logo: rnp,
      company: "Robots & Pencils",
      date: "Feb 2017 - Feb 2019",
      location: "Remote",
      team: "Farm Credit Canada",
      title: "Senior Software Developer",
      description: "Mobile and fullstack developer for <a href='https://www.fcc-fac.ca/' target='_blank'>Farm Credit Canada's</a> mobile banking applications and main website.",
      skills: ["iOS", "Android", "Angular", "Spring"]
    },
    {
      logo: google,
      company: "Google",
      date: "July 2015 - Nov 2016",
      location: "Austin, TX",
      team: "Google Play Services",
      title: "Software Developer",
      description: "Developed core infrastructure and tooling for <a href='https://developers.google.com/android/guides/overview' target='_blank'>Google's mobile services platform</a>.",
      skills: ["Java", "Android", "Python"]
    },
    {
      logo: ibm,
      company: "IBM",
      date: "Dec 2011 - July 2015",
      location: "Austin, TX",
      team: "Mobile Platform Team",
      title: "Software developer",
      description: "Mobile and fullstack developer for IBM's Mobile Application platform.",
      skills: ["iOS", "Android", "Java", "Spring"]
    },
    {
      logo: microsoft,
      company: "Microsoft",
      date: "May 2011 - Aug 2015",
      location: "Redmond, WA",
      team: "Office Solutions Framework",
      title: "Software Developer in Test Intern",
      description: "Created automated tests for for Microsoft Office products.",
      skills: ["C#"]
    },
    {
      logo: intel,
      company: "Intel",
      date: "Jan 2010 - Jan 2011",
      location: "Austin, TX",
      team: "Microcode Team",
      title: "Microcode Intern",
      description: "Developed static and runtime tooling for x86 microcode error detection and validation.",
      skills: ["C++", "Perl"]
    },
  ];

  static getItems() {
    return this.items;
  }

}

export { WorkItems }
export type { WorkItem }