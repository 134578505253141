import './AboutMe.scss';
import self_photo from '../../images/self-photo.png';
import linkedin_icon from '../../images/social/linkedin.svg';
import github_icon from '../../images/social/github.svg';
import email_icon from '../../images/social/email.svg';
import { SocialType } from '../../constants';
import { Analytics } from '../../analytics';

export default function AboutMe() {

  function getAge(dateString: string) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  function socialButtonClicked(type:number) {
    switch(type) {
      case SocialType.GITHUB:
        Analytics.socialButtonClicked("github");
        break;
      case SocialType.LINKEDIN:
        Analytics.socialButtonClicked("linkedin");
        break;
      case SocialType.EMAIL:
        Analytics.socialButtonClicked("email");
        break;
    }
  }

  return (
    <div className='aboutme-container'>

      <div className='aboutme-content'>
        <div className='aboutme-portrait'>
          <img src={self_photo} />
        </div>

        <div className='aboutme-info'>
          <div>
            <div className='heading'>Name:</div>
            <div>Jeremy Nortey</div>
          </div>

          <div>
            <div className='heading'>Profession:</div>
            <div>Software Developer</div>
          </div>

          <div>
            <div className='heading'>Age:</div>
            <div>{getAge("06/29/1989")}</div>
          </div>

          <div>
            <div className='heading'>Location:</div>
            <div>Austin, TX</div>
          </div>
        </div>
      </div>

      <div className='aboutme-social'>

        <div className='social-icon' onClick={() => {socialButtonClicked(SocialType.LINKEDIN)}}>
          <a href="https://www.linkedin.com/in/jeremy-nortey-b99b3641" target="_blank"><img src={linkedin_icon}/></a>
        </div>
        
        <div className='social-icon' onClick={() => {socialButtonClicked(SocialType.GITHUB)}}>
          <a href="http://github.com/nortey" target="_blank"><img src={github_icon}/></a>
        </div>
        
        <div className='social-icon' onClick={() => {socialButtonClicked(SocialType.EMAIL)}}>
          <a href="mailto:contact@jeremynortey.com" target="_blank"><img src={email_icon}/></a>
        </div>

      </div>

    </div>
  )
}
