interface EducationItem {
  name: string;
  date: string;
  degree: string;
  location: string;
}

class EducationItems {

  private static items: EducationItem[] = [
    {
      name: "University of Texas at Austin",
      date: "Aug 2009 - Dec 2011",
      degree: "Computer Science",
      location: "Austin, TX"
    },
    {
      name: "Texas Tech University",
      date: "Aug 2007 - May 2009",
      degree: "Computer Science",
      location: "Lubbock, TX",
    }
  ];

  static getItems() {
    return this.items;
  }

}

export { EducationItems }
export type { EducationItem }