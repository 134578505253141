import numari_icon from '../images/projects/icons/numberflow.png';
import numari_image1 from '../images/projects/numari/screen1.png';
import numari_image2 from '../images/projects/numari/screen2.png';
import numari_image3 from '../images/projects/numari/screen3.png';
import numari_image4 from '../images/projects/numari/screen4.png';
import numari_image5 from '../images/projects/numari/screen5.png';

import lettermash_icon from '../images/projects/icons/lettermash.png';
import lettermash_image1 from '../images/projects/lettermash/screen1.png';
import lettermash_image2 from '../images/projects/lettermash/screen2.png';
import lettermash_image3 from '../images/projects/lettermash/screen3.png';
import lettermash_image4 from '../images/projects/lettermash/screen4.png';
import lettermash_image5 from '../images/projects/lettermash/screen5.png';

import wordpoker_icon from '../images/projects/icons/wordpoker.png';
import wordpoker_image1 from '../images/projects/wordpoker/screen1.png'
import wordpoker_image2 from '../images/projects/wordpoker/screen2.png'
import wordpoker_image3 from '../images/projects/wordpoker/screen3.png'
import wordpoker_image4 from '../images/projects/wordpoker/screen4.png'
import wordpoker_image5 from '../images/projects/wordpoker/screen5.png'

import scramble_icon from '../images/projects/icons/scramble.png';
import scramble_image1 from '../images/projects/scramble/screen1.png'
import scramble_image2 from '../images/projects/scramble/screen2.png'
import scramble_image3 from '../images/projects/scramble/screen3.png'
import scramble_image4 from '../images/projects/scramble/screen4.png'
import scramble_image5 from '../images/projects/scramble/screen5.png'

import falldown_icon from '../images/projects/icons/falldown.png';
import falldown_image1 from '../images/projects/falldown/screen1.png'
import falldown_image2 from '../images/projects/falldown/screen2.png'
import falldown_image3 from '../images/projects/falldown/screen3.png'
import falldown_image4 from '../images/projects/falldown/screen4.png'

import blocktap_icon from '../images/projects/icons/blocktap.png';
import blocktap_image1 from '../images/projects/blocktap/screen1.png'
import blocktap_image2 from '../images/projects/blocktap/screen2.png'
import blocktap_image3 from '../images/projects/blocktap/screen3.png'
import blocktap_image4 from '../images/projects/blocktap/screen4.png'

import satvocab_icon from '../images/projects/icons/satvocab.png';
import satvocab_image1 from '../images/projects/satvocab/screen1.png'
import satvocab_image2 from '../images/projects/satvocab/screen2.png'
import satvocab_image3 from '../images/projects/satvocab/screen3.png'
import satvocab_image4 from '../images/projects/satvocab/screen4.png'

import idrop_icon from '../images/projects/icons/idrop.png';
import idrop_image1 from '../images/projects/idrop/screen1.png'
import idrop_image2 from '../images/projects/idrop/screen2.png'
import idrop_image3 from '../images/projects/idrop/screen3.png'
import idrop_image4 from '../images/projects/idrop/screen4.png'

import android from '../images/platforms/android.svg';
import ios from '../images/platforms/ios.svg';
import web from '../images/platforms/web.svg';

interface ProjectItem {
  icon: string;
  name: string;
  date: string;
  description:string;
  images:string[];
  platforms:string[];
  details:string;
  moreHeight:number;
}

class ProjectItems {

  private static items:ProjectItem[] = [
    {
      name: "Numari",
      icon: numari_icon,
      date: "Aug 15, 2022",
      description: "An original number puzzle similar to Sudoku. Available to play on the web at " + 
      "<a href='https://numari.com' target='_blank'>numari.com</a>.",
      images: [numari_image1, numari_image2, numari_image3, numari_image4, numari_image5],
      platforms: [android, ios, web],
      details: "<p>Numari is an original number puzzle based on existing logic puzzle called " +
      "<a href='https://en.wikipedia.org/wiki/Shikaku' target='_blank'>Shikaku</a>.</p>" +
      "<p>It was originally released in 2018 for iOS under the name \"Shikaku Twist\"  " + 
      "and re-released in 2019 for both iOS and Android under the name \"Number Flow\".</p>" +
      "<p>This game was featured in Android's <a href='https://play.google.com/store/apps/editorial_collection/promotion_topic_b000054_games_indie_corner_tp?hl=en_US&gl=US' target='_blank'>Indie Corner</a>, " + 
      "and you can view the (very cheesy) promotional ad I created for the game  " + 
      "<a href='https://www.youtube.com/watch?v=iY3Lh5c9nkc' target='_blank'>here</a>.</p>",
      moreHeight: 500
    },
    {
      name: "Lettermash",
      icon: lettermash_icon,
      date: "Feb 26, 2016",
      description: "A casual turn based word game where players can challenge their friends to compete by spelling words in a grid of " + 
      "letters. Lettermash is a remake and redesign of an earlier game I created in 2011 called \"Scramble Your Friends\".",
      images: [lettermash_image1, lettermash_image2, lettermash_image3, lettermash_image4, lettermash_image5],
      platforms: [android, ios],
      details: "<p>Due to unfortunate circumstances, the servers hosting of one of my previous games \"Scramble Your Friends\" " + 
      "were shut down. Previous players were unable to play the game, and every now and then I'd get (sometimes angry) " + 
      "emails about why the game no longer worked and when it would be coming back.</p> <p>Given the success of the original, I decided to " + 
      "remake \"Scramble Your Friends\" with updated artwork and changed the name to \"Lettermash\". Unfortunately, it was not as successful as its " + 
      "predecessor :( </p> <p>That being said, it was still a lot of fun to work on! Lettermash was the first game that I developed on Android. " + 
      "A video of the gameplay can be seen <a href='https://www.youtube.com/shorts/BLIvVUa7OAE' target='_blank'>here</a>.</p>" + 
      "<p>Artwork by <a href='https://www.artua.com/' target='_blank'>Artua</a>.</p>",
      moreHeight: 560
    },
    {
      name: "Word Poker",
      icon: wordpoker_icon,
      date: "Oct 1, 2012",
      description: "A twist on Texas Hold'em style poker where players gamble with words and letters instead of traditional playing cards.",
      images: [wordpoker_image1, wordpoker_image2, wordpoker_image3, wordpoker_image4, wordpoker_image5],
      platforms: [ios, web],
      details: "<p>In Word Poker, players could gamble in Texas Hold'em style poker, and and were able to play against " +
      "each other in real time (the backend was a socket server written with " +
      "<a href='https://twisted.org/' target='_blank'>Python Twisted</a>). Unfortunately, this game did not " +
      "perform well and ended serving as a (expensive) lesson about game design and development.</p><p>The " +
      "concept for the game sounded great on paper, but not so great in practice. In short, Word Poker added " +
      "several extra rules on top of traditional Texas Hold'em poker and gave players way too much to think " +
      "about. Trying to play the game was just way too confusing for new players.</p><p>As the game's creator, " +
      "I had played the game over and over again during development and testing. By the time I released it, " +
      "what seemed simple for me turned out to be confusing and complicated for someone who had never played " +
      "the game. I did not invest enough time in tutorials, instructions, or getting others to test the game " +
      "before its release. With that said, I learned more in the development of this game than any other game " +
      "I created.<p/><p>Artwork by <a href='https://www.artua.com/' target='_blank'>Artua</a>.</p>",
      moreHeight: 800
    },
    {
      name: "Scramble Your Friends",
      icon: scramble_icon,
      date: "Nov 19, 2011",
      description: "A casual turn based word game where players can challenge their friends to compete by spelling words in a grid of " + 
      "letters. Sort of a mixture between \"Words With Friends\" and \"Boggle\".",
      images: [scramble_image1, scramble_image2, scramble_image3, scramble_image4, scramble_image5],
      platforms: [ios],
      details: "<p>Scramble Your Friends was by far the most successful game I created, netting around $60,000 in total during its run. " + 
      " What made the game so popular you ask? Was it the compelling gameplay? Or its original design and concept?</p><p>Ha, no. A few  " + 
      "months after the release of this game, Zynga released <a href='https://www.youtube.com/watch?v=71q5-MyEn7Q' target='_blank'>Scramble  " + 
      "With Friends</a>, and thousands of players who were searching for that game found Scramble Your Friends instead. It was a sobering  " + 
      "lesson in the power of money and advertisements.</p><p>Given that the word \"Scramble\" is trademarked, and the phrase \"Your Friends\"  " + 
      "is really close to the trademarked phrase \"With Friends\", this game received multiple cease and desist letters during its run  " + 
      "(which were NOT fun to receive).</p>",
      moreHeight: 550
    },
    {
      name: "Falldown: Freefall",
      icon: falldown_icon,
      date: "Dec 30, 2010",
      description: "This is a remake of a game I created in 2009 called \"iDrop\" with updated artwork. " + 
      "Inspired by the TI-83 calculator game " + 
      "<a href='https://www.tiwizard.com/downloads/fall-down/' target='_blank'>Falldown</a>.",
      images: [falldown_image1, falldown_image2, falldown_image3, falldown_image4],
      platforms: [ios],
      details:"<p>Falldown Freefall was a remake of my previous game \"iDrop\", and another testament to my terrible " +
      "artistic abilities.</p><p>In the early days of the iPhone App Store, newly released apps were always " +
      "placed at the top of the \"new apps\" section. So regardless of how your app looked or performed, " +
      "you were almost always guaranteed several hundreds of downloads in the first week or so of your " +
      "release (this is very different from today, where your app will never see the light of day " +
      "without some kind of advertising or promotion).</p><p>Because of this, Falldown Freefall " +
      "performed relatively well compared to several of my previous apps, despite the fact that it looked ... like that.</p>",
      moreHeight: 460
    },
    {
      name: "SAT Vocab",
      icon: satvocab_icon,
      date: "Aug 5, 2010",
      description: "A study application for reviewing the most common SAT vocabulary words. Users can study using the application's " +
      "quiz mode, or browse the entire collection of words and definitions.",
      images: [satvocab_image1, satvocab_image2, satvocab_image3, satvocab_image4],
      platforms: [ios],
      details: "<p>At the time this application was created, games were all the rage in the App Store. There weren't a lot of popular education apps at the time, so I thought I could try to get in on that market early. I probably should have released it closer to when people were actually studying for the SAT though...</p>",
      moreHeight: 500
    },
    {
      name: "Block Tap",
      icon: blocktap_icon,
      date: "Nov 12, 2009",
      description: "A fast paced \"whack-a-mole\" type game in which players must quickly tap blocks in a grid as they begin to " + 
      "light up before the timer runs out.",
      images: [blocktap_image1, blocktap_image2, blocktap_image3, blocktap_image4],
      platforms: [ios],
      details: "<p>Block Tap was developed very quickly (and to be honest, very lazily) to try to capitalize on the early App Store  " + 
      "gold rush. I created this game when the App Store was still pretty new, back when every other day you'd hear about another " + 
      "game that turned its creator into a millionaire over night.</p><p>In my defense, the bar was pretty low back then, and it was " + 
      "a lot easier for low effort apps to make heaps of money. Needless to say, this game did not turn me into a millionaire.</p>",
      moreHeight: 400
    },
    {
      name: "iDrop",
      icon: idrop_icon,
      date: "Nov 5, 2009",
      description: "My first mobile application, inspired by the TI-83 calculator game " + 
      "<a href='https://www.tiwizard.com/downloads/fall-down/' target='_blank'>Falldown</a>.",
      images: [idrop_image1, idrop_image2, idrop_image3, idrop_image4],
      platforms: [ios],
      details: "<p>This was the very first mobile application I created, and was among the first 50,000 applications " +
      "released in the iPhone App Store.</p><p>At the time I was unfamiliar with UIKit, so I wrote this " +
      "using OpenGL since I was vaguely familiar with it back then (which in retrospect was clearly overkill " +
      "for this very simple 2D game). I wrote this before I learned how to use Git (or any source control " +
      "for that matter), so sadly the source code for this game is stuck in a Mac Mini buried in a landfill " +
      "somewhere.</p><p>Needless to say, the artwork for this game is a product of my (very poor) " +
      "photoshop skills. My ability to create digital artwork has not improved much since the creation " +
      "of this game.</p>",
      moreHeight: 550
    },
  ];

  static getItems() {
    return this.items;
  }

}

export { ProjectItems }
export type  { ProjectItem }