abstract class HeaderLinkType {
  static readonly ABOUT_ME = 1;
  static readonly EDUCATION = 2;
  static readonly WORK_HISTORY = 3;
  static readonly PROJECTS = 4;
}

abstract class SocialType {
  static readonly GITHUB = 1;
  static readonly LINKEDIN = 2;
  static readonly EMAIL = 3;
}

export { HeaderLinkType, SocialType }